import { invert } from 'lodash';
import {
  BuilderStepUI,
  BuilderSectionUI,
  isBuilderSectionUI,
  StepListItem,
  DocumentVersionUpdateUI,
  DocumentVersionUI,
} from 'components/DocumentBuilder/types';
import { isFilterCondition, isFilterGroup } from '@workerbase/domain/common';
import { getStepIdsUsedInDisplayCriteria, slugifySteps } from 'components/DocumentBuilder/utils';
import { DocumentVersionResponse, DocumentVersionUpdateBody } from '@workerbase/api/http/document';

const replaceIdWithVariableNameInDisplayCriteria = (
  steps: (BuilderStepUI | BuilderSectionUI)[],
): (BuilderStepUI | BuilderSectionUI)[] => {
  const idToVariableNameMap = invert(getVariableNameToIdMap(steps));

  return steps.map((step) => {
    if (isBuilderSectionUI(step)) {
      const section = step;
      const newSection: BuilderSectionUI = {
        ...section,
        displayCriteria: section.displayCriteria
          ? {
              ...section.displayCriteria,
              conditions: section.displayCriteria.conditions.map((conditionGroup) => {
                if (isFilterGroup(conditionGroup)) {
                  return {
                    ...conditionGroup,
                    conditions: conditionGroup.conditions.map((condition) => {
                      if (isFilterCondition(condition)) {
                        return {
                          ...condition,
                          name: idToVariableNameMap[condition.name],
                        };
                      }

                      return condition;
                    }),
                  };
                }

                return conditionGroup;
              }),
            }
          : null,
      };

      return newSection;
    }

    return step;
  });
};

const getVariableNameToIdMap = (steps: (BuilderStepUI | BuilderSectionUI)[], parentVariableName?: string) =>
  steps.reduce<Record<string, string>>((acc, item) => {
    if (isBuilderSectionUI(item)) {
      return { ...acc, ...getVariableNameToIdMap(item.steps, item.variableName) };
    }

    const variableNameKey = parentVariableName ? `${parentVariableName}.${item.variableName}` : item.variableName;
    acc[variableNameKey] = item._id;
    return acc;
  }, {});

export const replaceVariableNameWithIdInDisplayCriteria = (
  steps: (BuilderStepUI | BuilderSectionUI)[],
): (BuilderStepUI | BuilderSectionUI)[] => {
  const variableNameToIdMap = getVariableNameToIdMap(steps);

  return steps.map((step) => {
    if (isBuilderSectionUI(step)) {
      const section = step;
      const newSection: BuilderSectionUI = {
        ...section,
        displayCriteria: section.displayCriteria
          ? {
              ...section.displayCriteria,
              conditions: section.displayCriteria.conditions.map((conditionGroup) => {
                if (isFilterGroup(conditionGroup)) {
                  return {
                    ...conditionGroup,
                    conditions: conditionGroup.conditions.map((condition) => {
                      if (isFilterCondition(condition)) {
                        return {
                          ...condition,
                          name: variableNameToIdMap[condition.name],
                        };
                      }
                      return condition;
                    }),
                  };
                }
                return conditionGroup;
              }),
            }
          : null,
      };

      return newSection;
    }

    return step;
  });
};

const removeIdFromSteps = (steps: (BuilderStepUI | BuilderSectionUI)[]) =>
  steps.map((step) => {
    if (isBuilderSectionUI(step)) {
      const { _id, ...section } = step;
      const newSection: Omit<BuilderSectionUI, '_id'> = {
        ...section,
        steps: removeIdFromSteps(section.steps) as BuilderStepUI[],
      };

      return newSection;
    }

    const { _id, ...stepWithoutId } = step;
    return stepWithoutId;
  });

export const normalizeVersionFromAPI = (version: DocumentVersionResponse): DocumentVersionUI => ({
  ...version,
  documentBuilder: version.documentBuilder
    ? {
        ...version.documentBuilder,
        _id: version.documentBuilder._id || '',
        steps: normalizeStepsFromAPI(version.documentBuilder.steps),
      }
    : undefined,
});

export const normalizeStepsFromAPI = (builderSteps: (BuilderStepUI | BuilderSectionUI)[] = []): StepListItem[] => {
  const stepUsedInSectionsDisplayCriteriaMap: Record<string, string[]> = {};
  const steps = replaceVariableNameWithIdInDisplayCriteria(builderSteps);

  const stepListItems = steps.reduceRight<StepListItem[]>((acc, step) => {
    const stepListItem: StepListItem = {
      step: isBuilderSectionUI(step)
        ? {
            ...step,
            steps: step.steps.map((innerStep) => {
              const referencedInSteps = stepUsedInSectionsDisplayCriteriaMap[innerStep._id] || [];

              return {
                step: innerStep,
                meta: {
                  error: false,
                  referencedInSteps,
                  parentStepId: step._id,
                },
              };
            }),
          }
        : {
            ...step,
          },
      meta: {
        error: false,
        referencedInSteps: stepUsedInSectionsDisplayCriteriaMap[step._id] || [],
      },
    };

    if (isBuilderSectionUI(step)) {
      const displayCriteriaConditionStepIds = getStepIdsUsedInDisplayCriteria(step.displayCriteria);

      displayCriteriaConditionStepIds.forEach((conditionId) => {
        stepUsedInSectionsDisplayCriteriaMap[conditionId] = [
          ...(stepUsedInSectionsDisplayCriteriaMap[conditionId] || []),
          step._id,
        ];
      });
    }

    acc.unshift(stepListItem);

    return acc;
  }, []);

  return stepListItems;
};

export const normalizeVersionForAPI = (document: DocumentVersionUpdateUI): DocumentVersionUpdateBody => {
  const normalizeSteps = (steps?: StepListItem[]): DocumentVersionUpdateBody['steps'] => {
    if (!steps?.length) {
      return [];
    }

    const removeMetaFromSteps = steps.map(stepListItemToBuilderStep);
    const reSlugifySteps = slugifySteps(removeMetaFromSteps);
    const replaceIdWithVariableNameInDisplayCriteriaSteps = replaceIdWithVariableNameInDisplayCriteria(reSlugifySteps);
    const removeIdFromStepsSteps = removeIdFromSteps(replaceIdWithVariableNameInDisplayCriteriaSteps);

    return removeIdFromStepsSteps;
  };

  return {
    ...document,
    steps: normalizeSteps(document.steps),
  };
};

const stepListItemToBuilderStep = ({ step }: StepListItem): BuilderStepUI | BuilderSectionUI => {
  if (isBuilderSectionUI(step)) {
    return {
      ...step,
      steps: step.steps.map(({ step }) => step),
    };
  }
  return step as BuilderStepUI;
};
